<template>
  <div class="register">
    <div class="title">Create an account</div>
    <div class="font">
      <p>New user? Are you interested in becoming one of our customers ?</p>
      <p>
        Please complete our online registration form below to apply for an
        account.
      </p>
    </div>
    <div class="form col-sm-12 col-xs-12 col-lg-10 col-xl-10">
      <div class="single">
        <section>Nickname*</section>
        <input
          :class="err1.hint ? 'err-input' : 'right-input'"
          type="text"
          placeholder="Nickname"
          v-model="infomation.nickname"
          @focus="fun1"
        />
        <p v-if="err1.hint">{{ err1.msg }}</p>
      </div>
      <div class="double d-block d-lg-flex d-md-flex d-xl-flex">
        <div class="one col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <section>First Name*</section>
          <input
            :class="err2.hint ? 'err-input' : 'right-input'"
            type="text"
            placeholder="First Name"
            v-model="infomation.first_name"
            @focus="fun2"
          />
          <p v-if="err2.hint">{{ err2.msg }}</p>
        </div>

        <div class="one col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <section>Last Name*</section>
          <input
            :class="err3.hint ? 'err-input' : 'right-input'"
            type="text"
            placeholder="Last  Name"
            v-model="infomation.last_name"
            @focus="fun3"
          />
          <p v-if="err3.hint">{{ err3.msg }}</p>
        </div>
      </div>
      <div class="single">
        <section>Email*</section>
        <input
          :class="err4.hint ? 'err-input' : 'right-input'"
          type="text"
          placeholder="Email"
          v-model="infomation.email"
          @focus="fun4"
        />
        <p v-if="err4.hint">{{ err4.msg }}</p>
      </div>
      <div class="double d-block d-lg-flex d-md-flex d-xl-flex">
        <div class="one col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <section>Username*</section>
          <input
            :class="err5.hint ? 'err-input' : 'right-input'"
            type="text"
            placeholder="User Name"
            v-model="infomation.username"
            @focus="fun5"
          />
          <p v-if="err5.hint">{{ err5.msg }}</p>
        </div>

        <div class="one col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <section>Phone Number*</section>
          <input
            :class="err6.hint ? 'err-input' : 'right-input'"
            type="text"
            placeholder="Phone Number"
            v-model="infomation.mobile"
            @focus="fun6"
          />
          <p v-if="err6.hint">{{ err6.msg }}</p>
        </div>
      </div>
      <div class="double d-block d-lg-flex d-md-flex d-xl-flex">
        <div class="one col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <section>Password*</section>
          <input
            :class="err7.hint ? 'err-input' : 'right-input'"
            type="password"
            placeholder="Password"
            v-model="infomation.password"
            @focus="fun7"
          />
          <p v-if="err7.hint">{{ err7.msg }}</p>
        </div>
        <div class="one col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <section>Confirm Password*</section>
          <input
            :class="err8.hint ? 'err-input' : 'right-input'"
            type="password"
            placeholder="Confirm Password"
            v-model="infomation.confirmPassword"
            @focus="fun8"
          />
          <p v-if="err8.hint">{{ err8.msg }}</p>
        </div>
      </div>
      <div class="single">
        <section>Address*</section>
        <input
          :class="err9.hint ? 'err-input' : 'right-input'"
          type="text"
          placeholder="Address"
          v-model="infomation.address"
          @focus="fun9"
        />
        <p v-if="err9.hint">{{ err9.msg }}</p>
      </div>
      <div class="button" @click="submit">Create Account</div>
    </div>
  </div>
</template>
<script >
import Cookie from "cookie_js";
import { register } from "network/user";
export default {
  methods: {
    fun1() {
      this.err1.hint = false;
    },
    fun2() {
      this.err2.hint = false;
    },
    fun3() {
      this.err3.hint = false;
    },
    fun4() {
      this.err4.hint = false;
    },
    fun5() {
      this.err5.hint = false;
    },
    fun6() {
      this.err6.hint = false;
    },
    fun7() {
      this.err7.hint = false;
    },
    fun8() {
      this.err8.hint = false;
    },
    fun9() {
      this.err9.hint = false;
    },

    submit() {
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      // var phone = /^1[34578]\d{9}$/;
      if (this.infomation.nickname == "") {
        this.err1.hint = true;
        return;
      }
      if (this.infomation.first_name == "") {
        this.err2.hint = true;
        return;
      }
      if (this.infomation.last_name == "") {
        this.err3.hint = true;
        return;
      }

      if (this.infomation.email == "") {
        this.err4.hint = true;
        this.err4.msg = "Please enter your email address";
        return;
      } else if (!verify.test(this.infomation.email)) {
        this.err4.hint = true;
        this.err4.msg = "Please enter a valid email address";
        return;
      }
      if (this.infomation.username == "") {
        this.err5.hint = true;
        this.err5.msg = " Please enter your user name";
        return;
      } else if (this.infomation.username.length > 20) {
        this.err5.hint = true;
        this.err5.msg = "The user name does not exceed twenty characters";
        return;
      }

      if (this.infomation.mobile == "") {
        this.err6.hint = true;
        return;
      }

      if (this.infomation.password == "") {
        this.err7.hint = true;
        this.err7.msg = " Please enter your password";
        return;
      } else if (this.infomation.password.length < 6) {
        this.err7.hint = true;
        this.err7.msg = " Passwords need a minimum of 6 digits";
        return;
      }

      if (this.infomation.confirmPassword == "") {
        this.err8.hint = true;
        this.err8.msg = " Please enter your confirm password";
        return;
      } else if (this.infomation.confirmPassword != this.infomation.password) {
        this.err8.hint = true;
        this.err8.msg = "  The two passwords are different";
        return;
      }
      if (this.infomation.address == "") {
        this.err9.hint = true;
        return;
      }

      register(this.infomation).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          this.$store.commit("loginStausT");
          let data = res.data.data.userinfo;
          // this.$store.commit("changeToken", data);
          Cookie.set("token", data.token, data.expires_in);
          this.$store.dispatch("setAccount", data);
          this.$router.push("/member");
        } else if (res.data.code == 0) {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });

      // this.$http
      //   .post(this.$store.state.https + "/api/user/register", this.infomation, {
      //     emulateJSON: true,
      //   })
      //   .then((res) => {
      //     if (res.data.code == 1) {
      //       this.$notify({
      //         message: res.data.msg,
      //         type: "success",
      //         duration: 3000,
      //         position: "top-left",
      //         showClose: false,
      //         offset: 300,
      //       });
      //       this.$store.commit("loginStausT");
      //       let data = res.data.data.userinfo;
      //       // this.$store.commit("changeToken", data);
      //       Cookie.set("token", data.token, data.expires_in);
      //       this.$store.dispatch("setAccount", data);
      //       this.$router.push("/member");
      //     } else if (res.data.code == 0) {
      //       this.$notify({
      //         message: res.data.msg,
      //         type: "error",
      //         duration: 3000,
      //         position: "top-left",
      //         showClose: false,
      //         offset: 300,
      //       });
      //     }
      //   });
    },
  },
  data() {
    return {
      infomation: {
        nickname: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        mobile: "",
        password: "",
        confirmPassword: "",
        address: "",
      },
      err1: {
        hint: false,
        msg: "Please enter your company name",
      },
      err2: {
        hint: false,
        msg: "Please enter your first name",
      },
      err3: {
        hint: false,
        msg: "Please enter your last name",
      },
      err4: {
        hint: false,
        msg: "Please enter your email address",
      },
      err5: {
        hint: false,
        msg: "Please enter your user name",
      },
      err6: {
        hint: false,
        msg: "Please enter your phone number",
      },
      err7: {
        hint: false,
        msg: "Please enter your password",
      },
      err8: {
        hint: false,
        msg: "Please enter your confirm password",
      },
      err9: {
        hint: false,
        msg: "Please enter your address",
      },
    };
  },
  created() {},
};
</script>
<style scoped>
p {
  margin: 0;
}
.form p {
  color: #f56c6c;
  font-size: 0.4rem;
}
.register {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.title {
  color: #333333;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}
.font {
  text-align: center;
  color: #737373;
  font-size: 0.4rem;
  margin-top: 1rem;
}
.font p:last-child {
  margin-top: 0.25rem;
}
section {
  color: #666666;
  font-size: 0.4rem;
  font-weight: 600;
}
.single {
  margin-top: 0.75rem;
}
.double {
  justify-content: space-between;
}
.form {
  margin: 0.25rem auto;
}
.one {
  margin-top: 0.75rem;
  padding: 0;
}

.right-input {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.err-input {
  width: 100%;
  border: 1px solid #f56c6c;
  border-radius: 0.2rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.4rem;
  color: #999999;
}
.button {
  margin: 1rem auto;
  max-width: 5rem;
  font-size: 0.4rem;
  color: #fff;
  padding: 0.3rem 1rem;
  background: #4d4d4d;
  border-radius: 0.2rem;
  text-align: center;
  cursor: pointer;
}
.button:hover {
  background: #ff7f00;
  color: #fff;
}
</style>